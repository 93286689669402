import React from 'react';
import './AllCourses.css';

const UniqueCourses = () => {
  const uniqueCourses = [
    {
      title: 'ABACUS (Human Calculator)',
      icon: '🧮',
      for: 'All Age Groups',
      scholarship: 'Available',
      description: 'ABACUS helps in mental calculations.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'VEDIC MATHS (Cultural Techniques in India)',
      icon: '📐',
      for: 'All Age Groups',
      scholarship: 'Available',
      description: 'Vedic Maths simplifies complex calculations.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'IAS JUNIOR (Competitive Exam)',
      icon: '📚',
      for: 'School Students',
      scholarship: 'Available',
      description: 'IAS Junior prepares young minds for competitive exams.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
  ];

  return (
    <div className="all-courses-container">
      <h2 className="heading"> UNIQUE COURSES </h2>
      {uniqueCourses.map((course, index) => (
        <div className="course-card" key={index}>
          <div className="course-header">
            <span className="course-icon">{course.icon}</span>
            <h3>{course.title}</h3>
          </div>
          <div className="course-details">
            <p><strong>For:</strong> {course.for}. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>Scholarship:</strong> {course.scholarship}</p>
            <p>{course.description}</p>
            <a href={course.downloadLink} className="download-link">Download Course Details</a>
          </div>
          <div className="course-footer">
            <p><strong>Batch Starting:</strong> {course.batchStart}</p>
            <p><strong>Class Timings:</strong> {course.classTimings}</p>
            <div className="course-actions">
              <button className="action-button"><strong> Pay Registration Fee </strong></button>
              <button className="action-button"><strong> Request a Callback </strong></button>
              <button className="action-button"><strong> Find Center Location </strong></button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default UniqueCourses;

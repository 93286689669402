// src/components/Footer.js
import React from 'react';
import logo from '../assets/images/logo removebg.png';

const Footer = () => {
  return (
    <footer className="footer" id="contact">
      <p>Contact Us: +91 090111 99796 | Pdlscholarsacademy@gmail.com</p>
      <p>Address: House No.15, Shikshak Colony, Prashant Nagar, Garden Road, Near LIC Colony, Rukhmini Nagar, Amravati, Maharashtra 444606.</p>
      <div className="footer-designed">
        <p>© 2024 PDL Scholar's Academy. All Rights Reserved.<br />
          Designed and Managed by<br />
        <a href="https://bizonance.in">
        <img src={logo} alt="Logo" className="footer-logo" />
        </a></p>
      </div>
    </footer>
  );
};

export default Footer;

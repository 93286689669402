import React from 'react';

import coachingIcon from '../assets/images/buiding_icon.png';
import qualifiedIcon from '../assets/images/student_icon.png';
import facultyIcon from '../assets/images/teacher_icon.png';

const Legacy = () => {
  return (
    <div className="background-section">
    <section className="legacy-section">
      <h2>10+ YEARS OF LEGACY</h2>
      <div className="legacy-stats">
        <div className="legacy-item">
          <img src={coachingIcon} alt="Coaching Centers Icon" className="legacy-icon" />
          <h3>10+</h3>
          <p>Coaching centers in India</p>
        </div>
        <div className="legacy-item">
          <img src={qualifiedIcon} alt="Qualified Students Icon" className="legacy-icon" />
          <h3>131,70+</h3>
          <p>PDL Students qualified in 2024</p>
        </div>
        <div className="legacy-item">
          <img src={facultyIcon} alt="Expert Faculty Icon" className="legacy-icon" />
          <h3>100+</h3>
          <p>Expert Faculty</p>
        </div>
      </div>
    </section>
    </div>
  );
};

export default Legacy;

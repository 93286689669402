import img1 from '../assets/images/VIcon-7.jpeg';
import img2 from '../assets/images/VIcon-5.jpeg';
import img3 from '../assets/images/VIcon-8.jpeg';
import img4 from '../assets/images/Icon.jpg';

const StudentData = [
    {
      air: 129,
      name: "Ansh Deshmukh",
      classroom: "3 Year Classroom",
      image: img1,
    },
    {
      air: 131,
      name: "Shubham Patil",
      classroom: "4 Year Classroom",
      image: img2,
    },
    {
      air: 153,
      name: "Raghav Singh",
      classroom: "4 Year Classroom",
      image: img3,
    },
    {
        air: 253,
        name: "Rohan Agarwal",
        classroom: "3 Year Classroom",
        image: img4,
      },
      
    // Add more students similarly
  ];
  
  export default StudentData;
  
import React, { useState, useEffect } from 'react';
import logo from '../assets/images/PDL-logo.jpg'; // Import the logo
import { Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import NavDropdown from 'react-bootstrap/NavDropdown';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrolled, setScrolled] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false); // Close the menu on link click
  };

  // Effect to handle scroll event
  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      setScrolled(offset > 100);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <nav className={`navbar sticky-top ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-logo">
        <Link to="/">
        <img src={logo} alt="PDL Logo" className="logo" />
        <div className="logo-text">PDL SCHOLAR'S ACADEMY</div>
        </Link>
      </div>

      {/* Hamburger icon for mobile view */}
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      <ul className={`nav-links ${isOpen ? 'open' : ''}`}>
        <li><a href="/ " onClick={toggleMenu}>Home</a></li>
        <li><a href="/#about" onClick={toggleMenu}>About Us</a></li>

        {/* Courses Dropdown */}
        <NavDropdown title="Courses">
          <NavDropdown.Item as={Link} to="/src/components/UniqueCourses.js" onClick={closeMenu}>
            Unique Courses
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/src/components/CompetitiveExams.js" onClick={closeMenu}>
            Competitive Exams
          </NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/src/components/SmartCourses.js" onClick={closeMenu}>
            Smart Courses
          </NavDropdown.Item>
        </NavDropdown>

        <li><a href="/#announcementsevents" onClick={toggleMenu}>Activities</a></li>
        <li><a href="/Event" onClick={toggleMenu}>Events</a></li>
        <Nav.Link as={Link} to="/contact" onClick={toggleMenu}>
          Contact
        </Nav.Link>
      </ul>
    </nav>
  );
};

export default Navbar;

import React from 'react';
import './AllCourses.css';

const SmartCourses = () => {
  const smartCourses = [
    {
      title: 'ENGLISH SPEAKING',
      icon: '🗣️',
      for: 'All Age Groups',
      scholarship: 'Available',
      description: 'Enhance your English speaking skills.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'MENTAL MATHS',
      icon: '🧠',
      for: 'All Age Groups',
      scholarship: 'Available',
      description: 'Solve problems quickly with mental maths.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'PHONICS (Be the Best Reader)',
      icon: '🔤',
      for: 'Children',
      scholarship: 'Available',
      description: 'Improve reading skills using phonics.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'ROBOTICS HANDWRITING',
      icon: '🤖✍️',
      for: 'Tech Enthusiasts',
      scholarship: 'Available',
      description: 'Learn robotics and handwriting improvement.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
  ];

  return (
    <div className="all-courses-container">
      <h2 className="heading"> SMART COURSES </h2>
      {smartCourses.map((course, index) => (
        <div className="course-card" key={index}>
          <div className="course-header">
            <span className="course-icon">{course.icon}</span>
            <h3>{course.title}</h3>
          </div>
          <div className="course-details">
            <p><strong>For:</strong> {course.for}. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>Scholarship:</strong> {course.scholarship}</p>
            <p>{course.description}</p>
            <a href={course.downloadLink} className="download-link">Download Course Details</a>
          </div>
          <div className="course-footer">
            <p><strong>Batch Starting:</strong> {course.batchStart}</p>
            <p><strong>Class Timings:</strong> {course.classTimings}</p>
            <div className="course-actions">
              <button className="action-button"><strong> Pay Registration Fee </strong></button>
              <button className="action-button"><strong> Request a Callback </strong></button>
              <button className="action-button"><strong> Find Center Location </strong></button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SmartCourses;

import React from 'react';

import directorImage from '../assets/images/StudentC.webp'; // Update the path to your actual image

const DirectorMessage = () => {
  return (
    <section className="director-message-section">
      <div className="director-message-container">
        <div className="director-image">
          <img src={directorImage} alt="Director" />
        </div>
        <div className="director-message-content">
          <h2>A MESSAGE FROM THE DIRECTOR</h2>
          <p>
            Dear Students,<br /><br />
            Welcome to our coaching institute. Our mission is to guide you on the path to academic success with passion, dedication, and excellence. I encourage you to make the most of your time here and strive for greatness in every endeavor.
          </p>
          <p> Mrs. Pooja D. Lokhande (M.E., M.Sc.)</p>
          
        </div>
      </div>
    </section>
  );
};

export default DirectorMessage;

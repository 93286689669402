import React from 'react';
import visionImage from '../assets/images/VMW-1.jpg';
import missionImage from '../assets/images/VMW-2.jpg';

const VisionMission = () => {
  return (
    <section className="vision-mission-section">
      <div className="vision-mission-container">
        {/* Vision Card */}
        <div className="vision-mission-card vision-card">
          <div className="card-image">
            <img src={visionImage} alt="Vision" />
          </div>
          <div className='card-content'>
            <h2>Vision</h2>
            <p>
              Our vision at PDL Coaching Institute is to be a pioneering educational platform that empowers students with the knowledge, skills, and confidence to excel in both academic and competitive fields. We strive to foster a learning environment that promotes innovative thinking, holistic development, and intellectual curiosity, preparing students to be future leaders and problem solvers in a rapidly evolving world.
            </p>
          </div>
        </div>

        {/* Mission Card */}
        <div className="vision-mission-card mission-card">
          <div className="card-image">
            <img src={missionImage} alt="Mission" />
          </div>
          <div className='card-content'>
            <h2>Mission</h2>
            <p>
              Our mission is to provide a diverse range of high-quality, student-centric courses that cater to the individual learning needs of every student. Through a unique blend of traditional and modern teaching techniques, including ABACUS, Vedic Maths, competitive exam preparation, and skill-building courses such as coding, robotics, and English speaking, we aim to cultivate excellence in academics and personal growth. We are committed to creating a supportive and inclusive learning environment that encourages students to achieve their full potential and succeed in their academic journeys and beyond.
            </p>
          </div>
        </div>
       
      </div>
    </section>
  );
};

export default VisionMission;

import React from 'react';
import './AllCourses.css';

const CompetitiveExams = () => {
  const competitiveExams = [
    {
      title: 'SOF OLYMPIAD',
      icon: '🏅',
      for: 'School Students',
      scholarship: 'Available',
      description: 'Olympiad to test your skills.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'SILVER ZONE',
      icon: '🥈',
      for: 'School Students',
      scholarship: 'Available',
      description: 'Competitive exams at various levels.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'SCHOLARSHIP EXAMS',
      icon: '🎓',
      for: 'Meritorious Students',
      scholarship: 'Available',
      description: 'Scholarship exams to fund your studies.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'WISDOM OLYMPIAD',
      icon: '🧠',
      for: 'Young Learners',
      scholarship: 'Available',
      description: 'Olympiad for testing critical thinking.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'NASO (National Astronomy & Science Olympiad)',
      icon: '🔭',
      for: 'School Students',
      scholarship: 'Available',
      description: 'National-level astronomy and science olympiad.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'NSTSE (National Science Talent Search Exam)',
      icon: '🔬',
      for: 'School Students',
      scholarship: 'Available',
      description: 'Science talent search exam at national level.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    },
    {
      title: 'UNIFIELD COUNCIL',
      icon: '🏆',
      for: 'School Students',
      scholarship: 'Available',
      description: 'Council for interdisciplinary competitive exams.',
      downloadLink: '#',
      batchStart: 'November/December 2024',
      classTimings: 'Weekdays 3-4 hrs/day, Weekends 6 hrs/day',
    }
  ];

  return (
    <div className="all-courses-container">
      <h2 className="heading"> COMPETITIVE EXAMS </h2>
      {competitiveExams.map((exam, index) => (
        <div className="course-card" key={index}>
          <div className="course-header">
            <span className="course-icon">{exam.icon}</span>
            <h3>{exam.title}</h3>
          </div>
          <div className="course-details">
            <p><strong>For:</strong> {exam.for}. &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong>Scholarship:</strong> {exam.scholarship}</p>
            <p>{exam.description}</p>
            <a href={exam.downloadLink} className="download-link">Download Course Details</a>
          </div>
          <div className="course-footer">
            <p><strong>Batch Starting:</strong> {exam.batchStart}</p>
            <p><strong>Class Timings:</strong> {exam.classTimings}</p>
            <div className="course-actions">
              <button className="action-button"><strong> Pay Registration Fee </strong></button>
              <button className="action-button"><strong> Request a Callback </strong></button>
              <button className="action-button"><strong> Find Center Location </strong></button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CompetitiveExams;

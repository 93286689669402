import React from "react";
import { Link } from "react-router-dom";

const galleryImages = [
  {
    src: require("../assets/images/group.jpeg"), // Replace with the correct image paths
    alt: "Event 1",
  },
  {
    src: require("../assets/images/hall-img.jpeg"),
    alt: "Event 2",
  },
  {
    src: require("../assets/images/function.jpg"),
    alt: "Event 3",
  },
  {
    src: require("../assets/images/hall-image.jpeg"),
    alt: "Event 4",
  },
];

const Gallery = () => {
  return (
    <div className="gallery-section">
      <h2 className="gallery-title">LIFE AT PDL</h2>
      <div className="gallery-grid">
        {galleryImages.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
      <Link to="/Event" className="gallery-button">View Our Picture Gallery</Link>
      {/* <button className="gallery-button">View Our Picture Gallery</button> */}
      {/* <li><a href="/Event" onClick={toggleMenu}>Events</a></li> */}
    </div>
  );
};

export default Gallery;

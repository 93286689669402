import img1 from '../assets/images/VIcon-1.jpeg';
import img2 from '../assets/images/VIcon-2.jpeg';
import img3 from '../assets/images/VIcon-3.jpeg';
import img4 from '../assets/images/VIcon-4.png';

const StudentData = [
    {
      air: 23,
      name: "Arjun Kumar",
      classroom: "3 Year Classroom",
      image: img1,
    },
    {
      air: 124,
      name: "Shubham Patil",
      classroom: "4 Year Classroom",
      image: img2,
    },
    {
      air: 153,
      name: "Avyan Gawande",
      classroom: "4 Year Classroom",
      image: img3,
    },
    {
        air: 553,
        name: "Rohan Agarwal",
        classroom: "3 Year Classroom",
        image: img4,
      },
      
    // Add more students similarly
  ];
  
  export default StudentData;
  
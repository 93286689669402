// src/components/AboutUs.js
import React from 'react';
import aboutImage from '../assets/images/center.webp'; // Update with your actual image name
// import aboutImage1 from '../assets/images/';

const AboutUs = () => {
  return (
    <section className="about-us" id="about">
      <div className="about-us-content">
        <div className="text-content">
          <h2>ABOUT US</h2>
      <p>
      At PDL Coaching Institute, we are committed to nurturing young minds through a diverse range of unique and innovative courses designed to enhance their skills and knowledge. Our offerings include ABACUS (Human Calculator) and VEDIC MATHS, which promote quick mental calculations and cultural techniques from India. For early competitive exam preparation, we offer IAS Junior and General Knowledge, with age-appropriate syllabi.</p>
      <p>We also provide training for prestigious competitions such as the SOF Olympiad, Silver Zone, and various scholarship exams like NSTSE, NASO, and Unified Council. Our Smart Courses range from English Speaking and Coding to Mental Maths, Phonics, Robotics, and Handwriting improvement, catering to students from IGCSE, ICSE, CBSE, and State Boards. With our holistic approach, we aim to equip students with both academic excellence and essential life skills.
      </p>
      </div>
        <div className="image-content">
          <img src={aboutImage} alt="About Us" />
          {/* <img src={aboutImage1} alt="About Us" /> */}
        </div>
      </div>
      </section>
  );
};

export default AboutUs;

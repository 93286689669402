import React from 'react';
import './App.css';  // All section styles in one CSS file

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import AboutUs from './components/AboutUs';
import Legacy from './components/Legacy';
import Courses from './components/Courses';
import AnnouncementsEvents from './components/AnnouncementsEvents';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';
import VisionMission from './components/VisionMission';
import DirectorMessage from './components/DirectorMessage';
import Advantage from './components/Advantage';
import AwardsAccomplishments from './components/AwardsAccomplishments';
import Centers from './components/Centers';
import FeeStructure from './components/FeeStructure';
import Gallery from './components/Gallery';
import EventTable from './components/EventTable'; // Import the EventTable component
import EventDetails from './components/EventDetails'; // Import the EventDetails component
import Contact from './components/Contact'; // Adjust path based on where the Contact component is stored
import Results from './components/Result';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollToTop from './components/ScrollToTop';
import UniqueCourses from './components/UniqueCourses';
import CompetitiveExams from './components/CompetitiveExams';
import SmartCourses from './components/SmartCourses';
import ResultPage from './components/ResultPage';
import ResultsPage2 from './components/ResultPage2';
import ResultsPage3 from './components/ResultPage3';

// Layout Component wraps Navbar and Footer around all content
const Layout = ({ children }) => (
  <>
    <Navbar />  {/* Navbar at the top of every page */}
    <div className="main-content">
      {children}  {/* Main content of each page */}
    </div>
    <Footer />
  </>
);

const App = () => {
  return (
    <Router>
      <ScrollToTop />
      {/* Wrap all routes inside Layout */}
      <Layout>
        <Routes>
          <Route path="/" element={
            <>
              <HeroSection />
              <AboutUs />
              <Legacy />
              <VisionMission />
              <DirectorMessage />
              <Courses />
              <Results />
              <AwardsAccomplishments />
              <AnnouncementsEvents />
              <Testimonials />
              <Gallery />
              <Advantage />
              <FeeStructure />
              <Centers />
            </>
          } />
          <Route path="/src/components/UniqueCourses.js" element={<UniqueCourses />}/>
          <Route path="/src/components/CompetitiveExams.js" element={<CompetitiveExams />}/>
          <Route path="/src/components/SmartCourses.js" element={<SmartCourses />}/>
          <Route path="/event" element={<EventTable />} /> {/* Route for Event Table */}
          <Route path="/event/:id" element={<EventDetails />} /> {/* Route for Event Details */}
          <Route path="/contact" element={<Contact />} />
          <Route path="/ResultPage" element={<ResultPage />} />
          <Route path="/ResultPage2" element={<ResultsPage2 />} />
          <Route path="/ResultPage3" element={<ResultsPage3 />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;

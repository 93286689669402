import React, { useState, useEffect } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import './EventDetails.css'; // CSS for styling
import { useParams } from 'react-router-dom';
import event1 from '../assets/images/Img1.jpg';
import event2 from '../assets/images/img2.jpg';
import event3 from '../assets/images/img3.png';
import event4 from '../assets/images/img10.jpg';
import event5 from '../assets/images/Img6.jpg';
import video1 from '../assets/videos/video1.mp4';
import video2 from '../assets/videos/video2.mp4';
import video3 from '../assets/videos/video3.mp4';

const eventsData = {
  1: {
    name: 'Quiz Competition',
    media: [
      { type: 'image', src: event1 },
      { type: 'image', src: event5 },
      { type: 'image', src: event3 },
      { type: 'image', src: event4 },
      { type: 'image', src: event2 },
      { type: 'video', src: video1 },
      { type: 'video', src: video2 },
      { type: 'video', src: video3 },
    ],
    date: '15-10-2024',
    description: 'A fun and educational quiz competition with various challenges.',
  },
  2: {
    name: 'Science Olympiad',
    media: [
      { type: 'image', src: event4 },
      { type: 'image', src: event5 },
    ],
    date: '10-11-2024',
    description: 'An Olympiad for aspiring scientists to test their knowledge.',
  },
  3: {
    name: 'Abacus Competition',
    media: [
      { type: 'image', src: event1 },
      { type: 'image', src: event2 },
    ],
    date: '05-12-2024',
    description: 'A challenging abacus competition for students.',
  },
  4: {
    name: 'Maths Olympiad',
    media: [
      { type: 'image', src: event3 },
      { type: 'image', src: event4 },
    ],
    date: '01-10-2023',
    description: 'A maths Olympiad where students solve mathematical problems.',
  },
  5: {
    name: 'Spelling Bee',
    media: [
      { type: 'image', src: event5 },
    ],
    date: '20-11-2023',
    description: 'A spelling bee competition to challenge students’ vocabulary.',
  },
};

const EventDetails = () => {
  const { id } = useParams(); // Get event ID from URL
  const [event, setEvent] = useState(null);
  const [activeMediaIndex, setActiveMediaIndex] = useState(0); // Media index for Carousel
  const [showCarousel, setShowCarousel] = useState(false);
  const [playing, setPlaying] = useState(false); // Track video playback

  useEffect(() => {
    const eventData = eventsData[id];
    if (eventData) {
      setEvent(eventData);
    }
  }, [id]);

  if (!event) {
    return <div>Event not found.</div>;
  }

  const handleMediaClick = (index) => {
    setActiveMediaIndex(index);
    setShowCarousel(true);
  };

  const handleCloseCarousel = () => {
    setShowCarousel(false);
  };

  const handleVideoPlayPause = (e) => {
    const video = e.target.previousSibling;
    if (video.paused) {
      video.play();
      setPlaying(true);
    } else {
      video.pause();
      setPlaying(false);
    }
  };

  return (
    <div className="event-details-page">
      <h1>{event.name}</h1>
      <p>Date: {event.date}</p>
      <p>{event.description}</p>

      <div className="media-gallery">
        {event.media.map((media, index) => (
          media.type === 'image' ? (
            <img
              key={index}
              src={media.src}
              alt={`Event ${event.name} - ${index + 1}`}
              className="media-item"
              onClick={() => handleMediaClick(index)}
            />
          ) : (
            <div className="video-container" key={index}>
            <video
              key={index}
              className="media-item"
              muted
              controls={playing}
              onClick={() => handleMediaClick(index)}
            >
              <source src={media.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <button className="play-button" onClick={handleVideoPlayPause}>
                {playing ? '▶' : '▶'}
              </button>
            </div>
          )
        ))}
      </div>

      {showCarousel && (
        <div className="carousel-overlay">
          <Carousel activeIndex={activeMediaIndex} onSelect={setActiveMediaIndex} interval={null}>
            {event.media.map((media, index) => (
              <Carousel.Item key={index}>
                {media.type === 'image' ? (
                  <img
                    src={media.src}
                    alt={`Slide ${index + 1}`}
                    className="d-block w-100"
                  />
                ) : (
                  <video controls className="d-block w-100">
                    <source src={media.src} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </Carousel.Item>
            ))}
          </Carousel>
          <button className="close-carousel-btn" onClick={handleCloseCarousel}>Close</button>
        </div>
      )}
    </div>
  );
};

export default EventDetails;
